exports.components = {
  "component---src-components-templates-campus-page-index-tsx": () => import("./../../../src/components/templates/CampusPage/index.tsx" /* webpackChunkName: "component---src-components-templates-campus-page-index-tsx" */),
  "component---src-components-templates-trainings-page-index-tsx": () => import("./../../../src/components/templates/trainingsPage/index.tsx" /* webpackChunkName: "component---src-components-templates-trainings-page-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-boost-index-tsx": () => import("./../../../src/pages/boost/index.tsx" /* webpackChunkName: "component---src-pages-boost-index-tsx" */),
  "component---src-pages-capacit-index-tsx": () => import("./../../../src/pages/capacit/index.tsx" /* webpackChunkName: "component---src-pages-capacit-index-tsx" */),
  "component---src-pages-carriere-index-tsx": () => import("./../../../src/pages/carriere/index.tsx" /* webpackChunkName: "component---src-pages-carriere-index-tsx" */),
  "component---src-pages-confiez-nous-vos-projets-index-tsx": () => import("./../../../src/pages/confiez-nous-vos-projets/index.tsx" /* webpackChunkName: "component---src-pages-confiez-nous-vos-projets-index-tsx" */),
  "component---src-pages-download-pamphlet-index-tsx": () => import("./../../../src/pages/download-pamphlet/index.tsx" /* webpackChunkName: "component---src-pages-download-pamphlet-index-tsx" */),
  "component---src-pages-download-tuition-fees-index-tsx": () => import("./../../../src/pages/download-tuition-fees/index.tsx" /* webpackChunkName: "component---src-pages-download-tuition-fees-index-tsx" */),
  "component---src-pages-formations-tuition-fees-tsx": () => import("./../../../src/pages/formations/tuition-fees.tsx" /* webpackChunkName: "component---src-pages-formations-tuition-fees-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-vodacom-digital-lab-index-tsx": () => import("./../../../src/pages/vodacom-digital-lab/index.tsx" /* webpackChunkName: "component---src-pages-vodacom-digital-lab-index-tsx" */)
}

